<template>
  <div>
    <div class="my-10">
      <v-row>
        <v-col cols="12" md="8">
          <v-card class="pa-5 mb-5" cols="12">
            <div>
              <h1>{{ item.berita_judul }}</h1>
            </div>

            <div class="text--disabled caption mb-5">
              <span>INSPEKTORAT Kabupaten Pohuwato | </span>
              <span>
                {{ tglFormat(item.berita_created_at) }}
              </span>
            </div>

            <div class="mb-10">
              <v-img
                :src="getIMG(item.berita_thumbnail)"
                max-height="350"
              ></v-img>
              <div class="text-center caption">
                {{ item.berita_thumbnail_ket }}
              </div>
            </div>

            <div>
              <span v-html="item.berita_isi"></span>
            </div>
          </v-card>

          <v-btn block color="primary" to="/informasi/berita">
            <v-icon left>mdi-arrow-left</v-icon>
            Kembali
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="4"
          style="position: sticky; top: 70px; align-self: start"
        >
          <v-card class="pa-5"> PENGUMUMAN </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    page() {
      this.getBerita();
      window.scrollTo(0, 0);
    },
  },

  data: () => ({
    session: "",
    slug: "",

    item: {},
  }),

  async mounted() {
    this.slug = await this.$route.params.id;
    this.getData();
  },

  methods: {
    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/beritaGambar/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/beritaGambar/default.jpg";
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "berita/readmore/" + this.slug)
        .then((res) => {
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
